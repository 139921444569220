import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import renderSectionElement from "../helpers/renderSectionElm"

const projectTemplate = ({ pageContext, data }) => {
  const {
    content,
    seo,
    pageHeadline,
    pageText,
    pageImg,
    details,
    next,
  } = pageContext
  const findPageImg = () =>
    data.images.edges.find(({ node }) => node.name === pageImg.name)

  return (
    <Layout>
      <SEO
        title={seo.title || pageHeadline}
        description={seo.description}
        image={data.metaImage.childImageSharp.fixed.src}
      />
      <div className="main-container">
        <section>
          <h1 dangerouslySetInnerHTML={{ __html: pageHeadline }}></h1>
          <p
            className="subtext-h1"
            dangerouslySetInnerHTML={{ __html: pageText }}
          ></p>
          {details ? (
            <div className="project-specs-detail">
              <h6>Client</h6>
              {details.client}
              <h6>My Role</h6>
              {details.role}
              <h6>Launch</h6>
              {details.launch}
            </div>
          ) : null}

          {pageImg ? (
            <div className="single-img">
              {pageImg.extension ? (
                <div className={`gatsby-image-wrapper ${pageImg.imgClass}`}>
                  <img
                    src={`../../${pageImg.name}.${pageImg.extension}`}
                    alt={pageImg.name}
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
              ) : (
                <Img
                  fluid={findPageImg().node.childImageSharp.fluid}
                  alt={pageImg.name}
                />
              )}
            </div>
          ) : null}
        </section>
        {content.map(({ section }, index) => (
          <section key={`content_item_${index}`} data-sal="slide-up">
            {section.map((elm, i) => renderSectionElement(elm, i, data.images))}
          </section>
        ))}
      </div>
      <section className="next-project">
        <div className="main-container white">
          <Link to={next.path}>
            <div className="next-container">
              <h6 className="white">View next project</h6>
              <div className="horizontal-line white-bg"></div>
            </div>
            <h2 className="white">{next.teaser.headline}</h2>
            <p className="subtext-h2">{next.teaser.description}</p>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AllFilesToPath($regex: String, $metaImg: String) {
    images: allFile(filter: { relativePath: { regex: $regex } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    metaImage: file(relativePath: { regex: $metaImg }) {
      childImageSharp {
        fixed(width: 1200) {
          src
        }
      }
    }
  }
`

export default projectTemplate
